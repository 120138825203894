import React from "react";
import * as S from "./Paragraph.styles";

interface ParagraphProps {
  children: any;
}

function Paragraph({ children }: ParagraphProps) {
  return <S.Paragraph>{children}</S.Paragraph>;
}

export { Paragraph };
