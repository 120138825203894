import styled from "styled-components";

const ListItem = styled.li`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.025em;
  text-align: justify;
  margin: 0 0 8px 0;
  list-style: none;
`;

export { ListItem };
