import styled from "styled-components";
import { NavListItemProps } from "./NavListItem";

const NavListItem = styled.li`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 121.5%;
  letter-spacing: 0.005em;
  margin: 0 0 8px 0;
  position: relative;
`;

const Number = styled.span`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.005em;
  position: absolute;
  left: -18px;
  top: -2px;
`;

const Arrow = styled.span<{ hovered: boolean }>`
  transition: 120ms;
  margin: ${({ hovered }) => (hovered ? "0 0 0 10px;" : "0 0 0 6px")};
`;

export { NavListItem, Number, Arrow };
