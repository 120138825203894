import styled from "styled-components";

const Sup = styled.sup`
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
`;

export { Sup };
