import styled from "styled-components";

const CarouselController = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0 0 16px;
`;

export { CarouselController };
