import React, { ReactNode, useState } from "react";
import * as S from "./CarouselControllerButton.styles";
import BerninoRightArrow from "../../assets/bernino-right-arrow.svg";
import BerninoLeftArrow from "../../assets/bernino-left-arrow.svg";

interface CarouselControllerButtonProps {
  direction: "LEFT" | "RIGHT";
  onClick: () => void;
}

function CarouselControllerButton({
  direction,
  onClick,
}: CarouselControllerButtonProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <S.CarouselControllerButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {direction == "RIGHT" && <BerninoRightArrow />}
      {direction == "LEFT" && <BerninoLeftArrow />}
    </S.CarouselControllerButton>
  );
}

export { CarouselControllerButton };
