import styled from "styled-components";

const PageSectionHeading = styled.h1`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 56px 0;
`;

export { PageSectionHeading };
