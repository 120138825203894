import styled from "styled-components";

const NavListHeading = styled.h4`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.005em;
  opacity: 0.6;
  margin: 8px 0 16px 0;
`;

export { NavListHeading };
