import styled from "styled-components";

const Job = styled.section`
  margin: 0 0 128px 0;

  &:last-child {
    margin: 0;
  }
`;

export { Job };
