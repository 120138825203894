import React from "react";
import * as S from "./MarginNote.styles";

interface MarginNoteProps {
  children: React.ReactNode | string;
}

function MarginNote({ children }: MarginNoteProps) {
  return <S.MarginNote>{children}</S.MarginNote>;
}

export { MarginNote };
