import React from "react";
import * as S from "./NavListHeading.styles";

interface NavListHeadingProps {
  children: any;
  number?: number;
}

function NavListHeading({ number, children }: NavListHeadingProps) {
  return <S.NavListHeading>{children}</S.NavListHeading>;
}

export { NavListHeading };
