import React from "react";
import * as S from "./Header.styles";

interface HeaderProps {
  children: any;
}

function Header({ children }: HeaderProps) {
  return <S.Header>{children}</S.Header>;
}

export { Header };
