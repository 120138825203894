import React from "react";
import * as S from "./List.styles";

interface ListProps {
  children: any;
}

function List({ children }: ListProps) {
  return <S.List>{children}</S.List>;
}

export { List };
