import React, { ReactNode, useState } from "react";
import { CarouselController } from "../CarouselController";
import { CarouselItem } from "../CarouselItem";
import * as S from "./Carousel.styles";
import { useSwipeable } from "react-swipeable";

interface CarouselProps {
  children: ReactNode[];
}

function Carousel({ children }: CarouselProps) {
  const [activeFigure, setActiveFigure] = useState(0);

  const nextFigure = () => {
    setActiveFigure(
      activeFigure + 1 > children.length - 1 ? 0 : activeFigure + 1
    );
  };

  const previousFigure = () => {
    setActiveFigure(
      activeFigure - 1 < 0 ? children.length - 1 : activeFigure - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextFigure(),
    onSwipedRight: () => previousFigure(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <S.Carousel {...handlers}>
      <CarouselController
        previousFigure={previousFigure}
        nextFigure={nextFigure}
        setFigure={(index: number) => setActiveFigure(index)}
        length={children.length}
        active={activeFigure}
      />
      {children.map((child, i) => {
        return (
          <CarouselItem visible={i === activeFigure}>{child}</CarouselItem>
        );
      })}
    </S.Carousel>
  );
}

export { Carousel };
