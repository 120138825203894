import React from "react";
import { Caption } from "../Caption";
import * as S from "./ListItemCaption.styles";

interface ListItemCaptionProps {
  children: any;
}

function ListItemCaption({ children }: ListItemCaptionProps) {
  return (
    <S.ListItemCaption>
      <Caption>{children}</Caption>
    </S.ListItemCaption>
  );
}

export { ListItemCaption };
