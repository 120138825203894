import * as React from "react";
import styled from "styled-components";
import { setSessionPassword } from "@mkitio/gatsby-theme-password-protect/src/utils/utils";
import { useState } from "react";
import { PageHeader } from "../../../stories/ready/PageHeader";
import { Helmet } from "react-helmet";
import { GlobalStyle } from "../../../pages";

const SubmitButton = styled.input`
  background: none;
  border: 1px solid black;
  height: 32px;
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  border-radius: 0;
  font-size: 16px;
  letter-spacing: 0.025em;
  color: black;
  padding: 0 16px;
`;

const PasswordInput = styled.input`
  height: 100%;
  box-sizing: border-box;
  border-radius: 0;
  color: black;
  border: 1px solid black;
  margin: 0 8px 0 0;
  padding: 0 8px;
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.025em;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  height: 32px;
`;

const IndexPage = () => {
  const [password, setPassword] = useState("");

  return (
    <main>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/jvd4wdc.css" />
      </Helmet>
      <GlobalStyle />
      <PageHeader />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSessionPassword(password);
          window.location.reload();
        }}
      >
        <PasswordInput
          type={"text"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder={"Secret code"}
          autoComplete={"off"}
          spellCheck={"false"}
        />
        <SubmitButton type={"submit"} value={"Access"} />
      </Form>
    </main>
  );
};

export const Head = () => <title>Mike DiCarlo</title>;

export default IndexPage;
