import styled from "styled-components";

const Figure = styled.figure`
  width: 912px;
  margin: 0 -128px;

  @media only screen and (max-width: 704px) {
    width: 100vw;
    margin: 0 -32px;
  }
`;

export { Figure };
