import styled from "styled-components";

const CarouselControllerIndicator = styled.div``;

const IndicatorPadding = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
`;

const Indicator = styled.div<{ visible: boolean }>`
  width: 8px;
  height: 8px;
  border: 1px solid #000000;
  background-color: ${({ visible }) => (visible ? "#000000" : "transparent")};
`;

const IndicatorStrip = styled.div`
  display: flex;
  //gap: 6px;
`;

export {
  CarouselControllerIndicator,
  Indicator,
  IndicatorPadding,
  IndicatorStrip,
};
