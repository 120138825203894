import React from "react";
import * as S from "./PageSectionHeading.styles";

interface SectionHeaderProps {
  children: any;
}

function PageSectionHeading({ children }: SectionHeaderProps) {
  return <S.PageSectionHeading>{children}</S.PageSectionHeading>;
}

export { PageSectionHeading };
