import React from "react";
import * as S from "./FigureCaption.styles";

interface FigureCaptionProps {
  children: any;
}

function FigureCaption({ children }: FigureCaptionProps) {
  return <S.FigureCaption>{children}</S.FigureCaption>;
}

export { FigureCaption };
