import React from "react";
import * as S from "./JobHeaderHeading.styles";

interface JobHeaderHeadingProps {
  children: any;
}

function JobHeaderHeading({ children }: JobHeaderHeadingProps) {
  return <S.JobHeaderHeading>{children}</S.JobHeaderHeading>;
}

export { JobHeaderHeading };
