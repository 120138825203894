import React from "react";
import * as S from "./Anchor.styles";

interface AnchorProps {
  href: string;
  children: any;
}

function Anchor({ children, href }: AnchorProps) {
  return <S.Anchor href={href}>{children}</S.Anchor>;
}

export { Anchor };
