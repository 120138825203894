import React, { useState } from "react";
import BerninoRightArrow from "../../assets/bernino-right-arrow.svg";
import * as S from "./NavListItem.styles";

interface NavListItemProps {
  number?: number;
  children: any;
}

function NavListItem({ number, children }: NavListItemProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <S.NavListItem
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {number && <S.Number>{number}</S.Number>}
      {children}
      <S.Arrow hovered={hovered}>
        <BerninoRightArrow />
      </S.Arrow>
    </S.NavListItem>
  );
}

export { NavListItem, NavListItemProps };
