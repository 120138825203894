import styled from "styled-components";

const WorkSegmentSubheading = styled.h2`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 130%;
  margin: 0;
`;

export { WorkSegmentSubheading };
