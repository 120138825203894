import styled from "styled-components";

const Caption = styled.small`
  display: block;
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 150%;
  opacity: 0.6;
  text-align: left;
`;

export { Caption };
