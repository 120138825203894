import React from "react";
import * as S from "./NavList.styles";

interface NavListProps {
  children: any;
}

function NavList({ children }: NavListProps) {
  return <S.NavList>{children}</S.NavList>;
}

export { NavList };
