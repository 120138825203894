import React from "react";
import * as S from "./WorkSegmentSubheading.styles";

interface WorkSegmentSubheadingProps {
  children: any;
}

function WorkSegmentSubheading({ children }: WorkSegmentSubheadingProps) {
  return <S.WorkSegmentSubheading>{children}</S.WorkSegmentSubheading>;
}

export { WorkSegmentSubheading };
