import React from "react";
import * as S from "./Sup.styles";

interface SupProps {
  children: any;
}

function Sup({ children }: SupProps) {
  return <S.Sup>{children}</S.Sup>;
}

export { Sup };
