import React from "react";
import * as S from "./WorkSegmentSectionHeading.styles";

interface WorkSegmentSectionHeadingProps {
  children: any;
}

function WorkSegmentSectionHeading({
  children,
}: WorkSegmentSectionHeadingProps) {
  return <S.WorkSegmentSectionHeading>{children}</S.WorkSegmentSectionHeading>;
}

export { WorkSegmentSectionHeading };
