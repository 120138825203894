import styled from "styled-components";

const FigureCaption = styled.figcaption`
  display: block;
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 150%;
  opacity: 0.6;
  text-align: left;
  width: 656px;
  margin: 8px auto 0 auto;
  min-height: 48px;

  @media only screen and (max-width: 704px) {
    width: calc(100% - 64px);
    margin: 8px 32px 0 32px;
  }
`;

export { FigureCaption };
