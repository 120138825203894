import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import { Anchor } from "../stories/Anchor";
import { Carousel } from "../stories/Carousel";
import { Figure } from "../stories/Figure";
import { FigureCaption } from "../stories/FigureCaption";
import { Footnote } from "../stories/Footnote";
import { Job } from "../stories/Job";
import { JobHeader } from "../stories/JobHeader";
import { JobHeaderHeading } from "../stories/JobHeaderHeading";
import { List } from "../stories/List";
import { ListItem } from "../stories/ListItem";
import { ListItemCaption } from "../stories/ListItemCaption";
import { MarginNote } from "../stories/MarginNote";
import { NavList } from "../stories/NavList";
import { NavListHeading } from "../stories/NavListHeading";
import { NavListItem } from "../stories/NavListItem";
import { PageSection } from "../stories/PageSection";
import { PageSectionHeading } from "../stories/PageSectionHeading";
import { PageSections } from "../stories/PageSections";
import { Paragraph } from "../stories/Paragraph";
import { PageHeader } from "../stories/ready/PageHeader";
import { WorkSegment } from "../stories/WorkSegment";
import { WorkSegmentHeader } from "../stories/WorkSegmentHeader";
import { WorkSegmentHeading } from "../stories/WorkSegmentHeading";
import { WorkSegmentSection } from "../stories/WorkSegmentSection";
import { WorkSegmentSectionHeading } from "../stories/WorkSegmentSectionHeading";
import { WorkSegmentSubheading } from "../stories/WorkSegmentSubheading";
import { Sup } from "../stories/Sup";
import NavigationVideoH264 from "../assets/loupe-navigation_h264.mp4";
import NavigationVideoH265 from "../assets/loupe-navigation_h265.mp4";
import NavigationRedesignUserContextImage from "../images/loupe-navigation-redesign-user-context.png";
import NavigationRedesignUserContextMenuImage from "../images/loupe-navigation-redesign-user-context-menu.png";
import NavigationRedesignCompanyContextImage from "../images/loupe-navigation-redesign-company-context.png";
import NavigationRedesignProjectContextImage from "../images/loupe-navigation-redesign-project-context.png";
import DesignSystemCoverImage from "../images/loupe-design-system-cover.png";
import DesignSystemSheetsComponentImage from "../images/loupe-design-system-sheets-component.png";
import DesignSystemSheetsPatternImage from "../images/loupe-design-system-sheets-pattern.png";
import DesignSystemSheetsTokensImage from "../images/loupe-design-system-sheets-token.png";
import DesignSystemSheetsComponentsDetailImage from "../images/loupe-design-system-sheets-component-detail.png";
import TimberBoardPreview from "../images/timber-board-preview.png";

const GlobalStyle = createGlobalStyle`
  :root {
    background-color: #f3f3f3;
    width: 656px;
    margin: 0 auto;
  }
  
  body {
    margin: 0;
  }

  main {
    margin: 25vh 0;
  }
  
  @media only screen and (max-width: 704px) {
    :root {
      width: calc(100% - 64px);
      margin: 0 32px;
    }
    
    main {
      margin: 10vh 0;
    }
  }
  
`;

const IndexPage: React.FC<PageProps> = () => {
  return (
    <main>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/jvd4wdc.css" />
      </Helmet>
      <GlobalStyle />
      <PageHeader />
      <PageSections>
        <PageSection>
          <PageSectionHeading>About</PageSectionHeading>
          <Paragraph>
            I got my start by observing colleagues at Technicolor struggle with
            tasks that commercial software didn't solve, and took to developing
            tools for them myself<Sup>&dagger;</Sup>. Watching how they used
            these, listening to their feedback, and adapting them to their needs
            was a crash-course in software design and development.
            <MarginNote>
              <Sup>&dagger;</Sup>Actually in collaboration with an incredible
              engineering team whose support &amp; mentorship were invaluable.
            </MarginNote>
          </Paragraph>
          <Paragraph>
            Witnessing the impact of thoughtful, well-built software first-hand
            cemented the user-centric approach to research, design, and
            development that I still use today as I strive to imbue professional
            products with the same magic that makes users feel like it was{" "}
            <i>made</i> for them.
          </Paragraph>
          <Footnote>
            <Sup>&dagger;</Sup>Actually in collaboration with an incredible
            engineering team whose support &amp; mentorship were invaluable.
          </Footnote>
          <div
            style={{ marginTop: "32px", fontSize: "12px", textAlign: "center" }}
          >
            &#9724;
          </div>
          <div style={{ marginTop: "32px" }}>
            <Paragraph>
              My main interests are in design systems, design tools/tooling, and
              UI frameworks of all shapes and sizes&mdash;if there's a way to
              make a GUI you can be sure I want to learn it.
            </Paragraph>
            <Paragraph>
              Some of my auxiliary interests include typography (specifically
              typesetting and type design), parametric design, and collaborative
              work.
            </Paragraph>
            <Paragraph>
              When not working you'll probably catch me solving crossword
              puzzles, running long distances, reading all kinds of books,
              making/eating pasta, collecting printed matter, or downhill
              skiing. On occasion I still shoot a roll of film&mdash;a last
              vestige of studying cinematography.
            </Paragraph>
          </div>
        </PageSection>
        <PageSection>
          <PageSectionHeading>Work</PageSectionHeading>
          <Job>
            <JobHeader>
              <JobHeaderHeading>Loupe</JobHeaderHeading>
              <Paragraph>
                Loupe was a 3D animation production platform that offered the
                first turnkey cloud-native solution for digital asset
                management, integrated project management, and supporting
                services like distributed rendering, virtual workstations, and
                realtime review.
              </Paragraph>
              <Paragraph>
                It synthesized its founders’ years of experience building &
                running animation businesses into a product that aspired to be
                simple enough for individual artists to deploy, and scalable
                enough for established studios to adopt.
              </Paragraph>
              <Paragraph>
                I joined in 2020 and took over the only design role. In that
                capacity I introduced wire-framing and prototyping to a design
                process that had previously produced solely finished artifacts,
                and created a design system in addition to designing several{" "}
                <i>new</i> features&mdash;including resource management, render
                management, and configuration experiences&mdash;and enhancements
                to existing features, including schema authoring, realtime
                review, and automated status tracking.
              </Paragraph>
              <Paragraph>
                Loupe was acquired by Autodesk in late 2021.
              </Paragraph>
              <NavList>
                <NavListHeading>Work samples</NavListHeading>
                <NavListItem number={1}>
                  <Anchor href="#loupe-design-system">Design system</Anchor>
                </NavListItem>
                <NavListItem number={2}>
                  <Anchor href="#loupe-navigation-redesign">
                    Navigation re-design
                  </Anchor>
                </NavListItem>
              </NavList>
            </JobHeader>
            <WorkSegment id={"loupe-design-system"}>
              <WorkSegmentHeader>
                <WorkSegmentHeading number={1}>
                  Design system
                </WorkSegmentHeading>
                <WorkSegmentSubheading>
                  Codifying a much-loved visual style.
                </WorkSegmentSubheading>
              </WorkSegmentHeader>
              <div style={{ margin: "0 0 48px 0" }}>
                <Figure>
                  <img src={DesignSystemCoverImage} style={{ width: "100%" }} />
                  <FigureCaption>
                    Components from the Loupe Design System.
                  </FigureCaption>
                </Figure>
              </div>
              <div>
                <WorkSegmentHeader>
                  <WorkSegmentSectionHeading>
                    Overview
                  </WorkSegmentSectionHeading>
                  <Paragraph>
                    By the time I joined the team Loupe had already established
                    a unique visual style that was beloved by early adopters,
                    but wasn’t formally specified for designers and developers.
                    There was very little re-use in the front-end code, and
                    fragmentation between features was beginning to show. The
                    lack of documentation also made it difficult to onboard new
                    developers (and we expected new designers eventually too).
                  </Paragraph>
                  <Paragraph>
                    My first initiative was to begin work on a design system,
                    breaking the interface down into its atomic pieces, and
                    building it back up again from a set of well-defined parts.
                  </Paragraph>
                  {/*<Paragraph>*/}
                  {/*  While the primary goals were to reduce duplication and*/}
                  {/*  inconsistencies, increase development and design velocity,*/}
                  {/*  it was also an opportunity to lay the groundwork for*/}
                  {/*  theming support, accessibility features,*/}
                  {/*  internationalization, and responsiveness&mdash;none of which*/}
                  {/*  were accounted for in the application's current state.*/}
                  {/*</Paragraph>*/}
                  <Paragraph>
                    We couldn't stop development to work exclusively on the
                    design system, so it was important to not fundamentally
                    change the application's look and feel in the process.
                  </Paragraph>
                  {/*<Paragraph>*/}
                  {/*  We also used this as an opportunity to have the whole team*/}
                  {/*  adopt Figma, and to introduce wireframing and prototyping to*/}
                  {/*  the design process.*/}
                  {/*</Paragraph>*/}
                </WorkSegmentHeader>
                <WorkSegmentSection>
                  <WorkSegmentSectionHeading>Process</WorkSegmentSectionHeading>
                  {/*<List>*/}
                  {/*  <ListItem>*/}
                  {/*    Increase design team efficiency*/}
                  {/*    <ListItemCaption>*/}
                  {/*      Ut ipsum orci, accumsan sit amet elementum ut, tempor*/}
                  {/*      quis leo. Quisque egestas laoreet enim et scelerisque.*/}
                  {/*      Proin iaculis odio eget eros sagittis tempus.*/}
                  {/*    </ListItemCaption>*/}
                  {/*  </ListItem>*/}
                  {/*  <ListItem>*/}
                  {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                  {/*    <ListItemCaption>*/}
                  {/*      Ut ipsum orci, accumsan sit amet elementum ut, tempor*/}
                  {/*      quis leo. Quisque egestas laoreet enim et scelerisque.*/}
                  {/*      Proin iaculis odio eget eros sagittis tempus.*/}
                  {/*    </ListItemCaption>*/}
                  {/*  </ListItem>*/}
                  {/*  <ListItem>*/}
                  {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                  {/*    <ListItemCaption>*/}
                  {/*      Ut ipsum orci, accumsan sit amet elementum ut, tempor*/}
                  {/*      quis leo. Quisque egestas laoreet enim et scelerisque.*/}
                  {/*      Proin iaculis odio eget eros sagittis tempus.*/}
                  {/*    </ListItemCaption>*/}
                  {/*  </ListItem>*/}
                  {/*</List>*/}
                  <Paragraph>
                    We started by formally documenting some of the basic
                    elements of the existing visual language&mdash;the
                    typography, colours, and some iconography&mdash;in what
                    would become the token library of the new system. This gave
                    us a chance to figure out some practicalities like how best
                    to structure our Figma documents, but more importantly was
                    an opportunity to introduce the engineering team to a new
                    way of approaching front-end development.
                  </Paragraph>
                  <Paragraph>
                    We rounded out the token library with a relative spacing
                    system and simpler elevation rules and felt that we had
                    enough of a foundation to start work on component libraries,
                    which would make up the bulk of the system. In designing
                    components we adhered to an atomic design mentality,
                    de-composing them as much as possible to maximize sharing.
                  </Paragraph>
                  <Paragraph>
                    To ensure a consistent user experience when combining
                    components and building experiences we added a pattern
                    library to document common conventions and expected
                    behaviour&mdash;some of our most common patterns included
                    dragging and dropping, transitioning between states,
                    validating input, communicating errors, and performing
                    potentially destructive actions.
                  </Paragraph>
                </WorkSegmentSection>
                <WorkSegmentSection>
                  <WorkSegmentSectionHeading>Results</WorkSegmentSectionHeading>
                  <Paragraph>
                    The token, component, and pattern libraries grew to contain
                    over forty elements, and although many became stable, they
                    continued to evolve as they were implemented in code or as
                    new features demanded new functionality.
                  </Paragraph>
                  <Paragraph>
                    The system began to pay dividends almost immediately with
                    thorough documentation in a single source of truth
                    dramatically improving developer confidence in hand-offs
                    from design, and acting as a reference against which we
                    could audit existing experiences.
                  </Paragraph>
                  <Carousel>
                    <Figure>
                      <img
                        src={DesignSystemSheetsPatternImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The Drag & Drop pattern sheet from the design system's
                        pattern library.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={DesignSystemSheetsComponentImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The Form Input component sheet from the design system's
                        component library.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={DesignSystemSheetsTokensImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The Spacing token sheet from the design system's token
                        library.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={DesignSystemSheetsComponentsDetailImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        Detail on a component sheet showing spacing guidelines
                        and best practices, part of the standard documentation
                        for all components.
                      </FigureCaption>
                    </Figure>
                  </Carousel>
                </WorkSegmentSection>
              </div>
            </WorkSegment>
            <WorkSegment id={"loupe-navigation-redesign"}>
              <WorkSegmentHeader>
                <WorkSegmentHeading number={2}>
                  Navigation re-design
                </WorkSegmentHeading>
                <WorkSegmentSubheading>
                  Embracing the way people work.
                </WorkSegmentSubheading>
              </WorkSegmentHeader>
              <div>
                <div style={{ margin: "0 0 48px 0" }}>
                  <Figure>
                    <video controls loop muted style={{ width: "100%" }}>
                      <source
                        src={`${NavigationVideoH265}#t=0.001`}
                        type="video/mp4"
                      />
                      <source
                        src={`${NavigationVideoH264}#t=0.001`}
                        type="video/mp4"
                      />
                    </video>
                    <FigureCaption>
                      The re-designed navigation experience, built around
                      contexts and activities (plus a look at several new or
                      re-designed activity modules).
                    </FigureCaption>
                  </Figure>
                </div>
                <WorkSegmentHeader>
                  <WorkSegmentSectionHeading>
                    Overview
                  </WorkSegmentSectionHeading>
                  <Paragraph>
                    Loupe was conceived as a production management platform for
                    3D artists; a departure from similar tools that were almost
                    invariably built by and for project managers. It had staked
                    its claim on radical simplicity—offering a small set of
                    opinionated features, putting them at a user’s fingertips,
                    and otherwise staying out of their way.
                  </Paragraph>
                  <Paragraph>
                    This ethos won over individual artists, who were
                    enthusiastic early adopters, but reality dictated that we
                    sell Loupe to studios, which were businesses that included
                    users with different goals. The personas we designed for
                    quickly grew to include everyone from the project manager to
                    the studio administrator.
                  </Paragraph>
                  <Paragraph>
                    As we added features to meet more needs we kept bumping up
                    against the project-oriented navigation paradigm, which
                    assumed that entry-point to and perspective on one's work,
                    but which we realized was not even held by the 3D
                    artist&mdash;our primary persona.
                  </Paragraph>
                </WorkSegmentHeader>
                <WorkSegmentSection>
                  <WorkSegmentSectionHeading>Process</WorkSegmentSectionHeading>
                  <Paragraph>
                    We wanted the interface to reflect the mental model that
                    people had of their work, and a key observation that came
                    from user interviews was that they typically thought of
                    their tasks as fitting into one of three contexts:
                  </Paragraph>
                  <List>
                    <ListItem>
                      The "user" context
                      <ListItemCaption>
                        This was for information and activities that could be
                        oriented around an individual. It might come from other
                        contexts, like tasks on a project, but when consolidated
                        it took on new meaning.
                      </ListItemCaption>
                    </ListItem>
                    <ListItem>
                      The "company" context
                      <ListItemCaption>
                        This was for information and activities that could be
                        oriented around an organization that a user belonged to.
                        It provided a "35,000 foot" view across all projects and
                        users.
                      </ListItemCaption>
                    </ListItem>
                    <ListItem>
                      The "project" context
                      <ListItemCaption>
                        This was for information and activities that could be
                        oriented around a specific deliverable. Loupe had been
                        built around this context alone.
                      </ListItemCaption>
                    </ListItem>
                  </List>
                  <Paragraph>
                    But no user stayed entirely within a single context, they
                    changed as necessary to perform certain actions or
                    activities, and often had to re-orient themselves when
                    returning to their primary task—friction that we wanted to
                    remove, especially as we hoped to absorb more and more of
                    their tasks into Loupe.
                  </Paragraph>
                  <Paragraph>
                    At the same time, we wanted to make Loupe more equitable for
                    all of its users, and therefore didn't want to give any one
                    context pride of place, or bury an activity critical to one
                    persona, however minor, in a menu or peripheral screen.
                  </Paragraph>
                  <Paragraph>
                    Lastly, we wanted to improve the interface for activities
                    that occurred within contexts, to make this relationship
                    clear, while laying the groundwork for Loupe’s long term
                    platform vision, which meant anticipating user extensibility
                    via plugins, and support for switching not just between
                    contexts, but also switching the contexts themselves
                    <Sup>&dagger;</Sup>.
                    <MarginNote>
                      <Sup>&dagger;</Sup>In addition to switching projects,
                      users were expected to have multiple accounts, and/or
                      accounts in multiple organizations.
                    </MarginNote>
                  </Paragraph>
                  <Footnote>
                    <Sup>&dagger;</Sup>In addition to switching projects, users
                    were expected to have multiple accounts, and/or accounts in
                    multiple organizations.
                  </Footnote>
                </WorkSegmentSection>
                <WorkSegmentSection>
                  <WorkSegmentSectionHeading>Results</WorkSegmentSectionHeading>
                  <Paragraph>
                    Contexts are placed together and given priority along the
                    top of the application in a pattern inspired by many digital
                    content creation tools' organization around jobs to be done,
                    while activities for the current context are listed along
                    the left side of screen in a clear (and common) toolbox
                    pattern that we were already using in some experiences.
                  </Paragraph>
                  <Carousel>
                    <Figure>
                      <img
                        src={NavigationRedesignUserContextImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The User Context, in which all activities are oriented
                        around a person. Pictured is a new user dashboard, a
                        much-requested feature.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={NavigationRedesignUserContextMenuImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        Options and actions for the User Context are close at
                        hand in the context's menu.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={NavigationRedesignCompanyContextImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The Company Context contains activities that are
                        oriented around an organization that a user is part of.
                        Pictured is a new Resource Management activity module.
                      </FigureCaption>
                    </Figure>
                    <Figure>
                      <img
                        src={NavigationRedesignProjectContextImage}
                        style={{ width: "100%" }}
                      />
                      <FigureCaption>
                        The Project Context contains activities specific to the
                        selected project. Pictured is the Entity Management
                        activity module, with a re-designed tree view.
                      </FigureCaption>
                    </Figure>
                  </Carousel>
                  <Paragraph>
                    These patterns proved to be intuitive for new users{" "}
                    <i>and</i> more efficient for power users when compared to
                    our previous navigation, while also allowing for easy
                    configuration of activity modules&mdash;an important
                    business consideration as we experimented with different
                    SKUs&mdash;and providing a natural extension point for
                    user-created plug-ins&mdash;a product priority.
                  </Paragraph>
                </WorkSegmentSection>
              </div>
            </WorkSegment>
          </Job>
        </PageSection>
      </PageSections>
      {/*<div style={{ marginTop: "32px", fontSize: "12px", textAlign: "center" }}>*/}
      {/*  &#9724;*/}
      {/*</div>*/}
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Mike DiCarlo</title>;

export { GlobalStyle };
