import React from "react";
import * as S from "./ListItem.styles";

interface ListItemProps {
  children: any;
}

function ListItem({ children }: ListItemProps) {
  return <S.ListItem>{children}</S.ListItem>;
}

export { ListItem };
