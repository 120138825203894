import styled from "styled-components";

const NavList = styled.ul`
  padding: 0;
  margin: 48px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  width: 192px;
  list-style: none;
`;

export { NavList };
