import React from "react";
import * as S from "./PageSection.styles";

interface SectionProps {
  children: any;
}

function PageSection({ children }: SectionProps) {
  return <S.PageSection>{children}</S.PageSection>;
}

export { PageSection };
