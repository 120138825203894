import React, { ReactNode } from "react";
import * as S from "./CarouselItem.styles";

interface CarouselItemProps {
  children: ReactNode;
  visible: boolean;
}

function CarouselItem({ children, visible }: CarouselItemProps) {
  return <S.CarouselItem visible={visible}>{children}</S.CarouselItem>;
}

export { CarouselItem, CarouselItemProps };
