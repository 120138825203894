import React from "react";
import * as S from "./WorkSegment.styles";

// TODO: Refactor this as JobSegment since it's a child of Job

interface WorkSegmentProps {
  children: any;
  id: string;
}

function WorkSegment({ id, children }: WorkSegmentProps) {
  return <S.WorkSegment id={id}>{children}</S.WorkSegment>;
}

export { WorkSegment };
