import React from "react";
import * as S from "./Caption.styles";

interface CaptionProps {
  children: any;
}

function Caption({ children }: CaptionProps) {
  return <S.Caption>{children}</S.Caption>;
}

export { Caption };
