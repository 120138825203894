import React from "react";
import * as S from "./CarouselControllerIndicator.styles";

interface CarouselControllerIndicatorProps {
  length: number;
  active: number;
  setFigure: (index: number) => void;
}

function CarouselControllerIndicator({
  length,
  active,
  setFigure,
}: CarouselControllerIndicatorProps) {
  return (
    <S.CarouselControllerIndicator>
      <S.IndicatorStrip>
        {[...Array(length)].map((_, i) => (
          <S.IndicatorPadding onClick={() => setFigure(i)}>
            <S.Indicator visible={active === i} />
          </S.IndicatorPadding>
        ))}
      </S.IndicatorStrip>
    </S.CarouselControllerIndicator>
  );
}

export { CarouselControllerIndicator };
