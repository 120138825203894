import styled from "styled-components";

const CarouselControllerButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  border-radius: 2px;
  transition: background-color 120ms linear;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export { CarouselControllerButton };
