import React from "react";
import * as S from "./PageSections.styles";

interface PageSectionsProps {
  children: any;
}

function PageSections({ children }: PageSectionsProps) {
  return <S.PageSections>{children}</S.PageSections>;
}

export { PageSections };
