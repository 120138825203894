import styled from "styled-components";

const WorkSegmentHeading = styled.h1`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 8px 0;
  position: relative;
`;

const Number = styled.span`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 121.5%;
  letter-spacing: 0.005em;
  position: absolute;
  left: -18px;
  text-align: right;
  top: 4px;
`;

export { WorkSegmentHeading, Number };
