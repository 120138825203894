import styled from "styled-components";

const Paragraph = styled.p`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.025em;
  text-align: justify;
  position: relative;

  @media only screen and (max-width: 704px) {
    text-align: left;
  }
`;

export { Paragraph };
