import styled from "styled-components";
import { CarouselItemProps } from "./CarouselItem";

const CarouselItem = styled.div<Pick<CarouselItemProps, "visible">>`
  //position: absolute;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  display: ${({ visible }) => (visible ? "initial" : "none")};
  // opacity: ${({ visible }) => (visible ? 1 : 0)};
  //transition: display 600ms linear, opacity 600ms linear;
`;

export { CarouselItem };
