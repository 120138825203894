import React from "react";
import { CarouselControllerButton } from "../CarouselControllerButton";
import { CarouselControllerIndicator } from "../CarouselControllerIndicator";
import * as S from "./CarouselController.styles";

interface CarouselControllerProps {
  previousFigure: () => void;
  nextFigure: () => void;
  setFigure: (index: number) => void;
  length: number;
  active: number;
}

function CarouselController({
  previousFigure,
  nextFigure,
  setFigure,
  length,
  active,
}: CarouselControllerProps) {
  return (
    <S.CarouselController>
      <CarouselControllerButton
        direction={"LEFT"}
        onClick={() => previousFigure()}
      />
      <CarouselControllerIndicator
        setFigure={setFigure}
        length={length}
        active={active}
      />
      <CarouselControllerButton
        direction={"RIGHT"}
        onClick={() => nextFigure()}
      />
    </S.CarouselController>
  );
}

export { CarouselController };
