import React from "react";
import * as S from "./WorkSegmentHeading.styles";

interface WorkSegmentHeadingProps {
  children: any;
  number?: number;
}

function WorkSegmentHeading({ number, children }: WorkSegmentHeadingProps) {
  return (
    <S.WorkSegmentHeading>
      {number && <S.Number>{number}</S.Number>}
      {children}
    </S.WorkSegmentHeading>
  );
}

export { WorkSegmentHeading };
