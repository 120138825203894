import styled from "styled-components";

const WorkSegmentSectionHeading = styled.h3`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  opacity: 0.6;
`;

export { WorkSegmentSectionHeading };
