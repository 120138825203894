import React from "react";
import * as S from "./Job.styles";

interface JobProps {
  children: any;
}

function Job({ children }: JobProps) {
  return <S.Job>{children}</S.Job>;
}

export { Job };
