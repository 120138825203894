import styled from "styled-components";

const Title = styled.h1`
  font-family: jaf-bernino-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.01em;
  margin: 0 0 24px 0;
`;

export { Title };
