import React from "react";
import * as S from "./Subtitle.styles";

interface SubtitleProps {
  children: any;
}

function Subtitle({ children }: SubtitleProps) {
  return <S.Subtitle>{children}</S.Subtitle>;
}

export { Subtitle };
