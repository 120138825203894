import React from "react";
import * as S from "./Figure.styles";

interface FigureProps {
  children: any;
}

function Figure({ children }: FigureProps) {
  return <S.Figure>{children}</S.Figure>;
}

export { Figure };
