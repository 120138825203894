import React from "react";
import * as S from "./Title.styles";

interface TitleProps {
  children: any;
}

function Title({ children }: TitleProps) {
  return <S.Title>{children}</S.Title>;
}

export { Title };
