import React from "react";
import * as S from "./WorkSegmentHeader.styles";

interface WorkSegmentHeaderProps {
  children: any;
}

function WorkSegmentHeader({ children }: WorkSegmentHeaderProps) {
  return <S.WorkSegmentHeader>{children}</S.WorkSegmentHeader>;
}

export { WorkSegmentHeader };
