import React from "react";
import * as S from "./Footnote.styles";

interface FootnoteProps {
  children: any;
}

function Footnote({ children }: FootnoteProps) {
  return <S.Footnote>{children}</S.Footnote>;
}

export { Footnote };
