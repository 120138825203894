import styled from "styled-components";

const MarginNote = styled.aside`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0.025em;
  opacity: 0.6;
  width: 160px;
  position: absolute;
  left: 0;
  top: 8px;
  text-align: right;
  margin: 0 -184px;

  @media only screen and (max-width: 1048px) {
    display: none;
  }
`;

export { MarginNote };
