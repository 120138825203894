import styled from "styled-components";

const PageSection = styled.article`
  margin: 0 0 256px 0;

  &:last-child {
    margin: 0;
  }
`;

export { PageSection };
