import styled from "styled-components";

const JobHeaderHeading = styled.h1`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

export { JobHeaderHeading };
