import styled from "styled-components";

const Footnote = styled.aside`
  font-family: jaf-bernino-sans-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0.025em;
  opacity: 0.6;
  display: none;
  width: 60%;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  margin-top: 16px;

  @media only screen and (max-width: 1048px) {
    display: inline-block;
  }
`;

export { Footnote };
