import React from "react";
import * as S from "./JobHeader.styles";

interface JobHeaderProps {
  children: any;
}

function JobHeader({ children }: JobHeaderProps) {
  return <S.JobHeader>{children}</S.JobHeader>;
}

export { JobHeader };
