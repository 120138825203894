import React from "react";
import * as S from "./WorkSegmentSection.styles";

interface WorkSegmentSectionProps {
  children: any;
}

function WorkSegmentSection({ children }: WorkSegmentSectionProps) {
  return <S.WorkSegmentSection>{children}</S.WorkSegmentSection>;
}

export { WorkSegmentSection };
