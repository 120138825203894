import styled from "styled-components";

const Subtitle = styled.h2`
  font-family: jaf-bernino-sans-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: 0.02em;
  margin: 0;
`;

export { Subtitle };
