import React from "react";
import { Header } from "../../Header";
import { Subtitle } from "../../Subtitle";
import { Title } from "../../Title";
import * as S from "./PageHeader.styles";

function PageHeader() {
  return (
    <Header>
      <Title>Mike DiCarlo</Title>
      <div style={{ width: "80%" }}>
        <Subtitle>
          I'm a designer &amp; technologist improving people's productivity by
          building them better tools.
        </Subtitle>
      </div>
      <div style={{ marginTop: "16px" }}>
        <Subtitle>
          <small>I live in Canada and work at Autodesk.</small>
        </Subtitle>
      </div>
    </Header>
  );
}

export { PageHeader };
